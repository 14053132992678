import React, { useState } from 'react';
import NavItem from './../NavItem/NavItem';
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import Images from '../../Images';
import { Link } from 'react-router-dom';
import { getToken } from '../../authetication/loginState';
import Arrow from '../../images/arrow-down.png';

const Navlist = ({ loginState, open, setOpen, mobileState }) => {
  const [open_students_dropdown, setOpen_students_dropdown] = useState(false);
  let navigate = useNavigate();
  const [open_recruiters_dropdown, setOpen_recruiters_dropdown] = useState(false);
  const location = useLocation();

  const change_students_dropdown = (e) => {
    e.preventDefault();
    setOpen_students_dropdown(!open_students_dropdown);
  };

  const change_recruiters_dropdown = (e) => {
    e.preventDefault();
    setOpen_recruiters_dropdown(!open_recruiters_dropdown);
  };

  const getDropbtnClass = () => {
    return location.pathname === '/' ? 'dropbtn home' : 'dropbtn other';
  };

  const scrollToComponentCheetah = () => {
    document.getElementById('cheetah_mode').scrollIntoView({ behavior: 'smooth' });
    if (mobileState) {
      setOpen(false); // Close the dropdown
    }
  };

  const handleCheetahApplyClick = () => {
    navigate('/');
    setTimeout(() => {
      document.getElementById('cheetah_mode').scrollIntoView({ behavior: 'smooth' });
    }, 500); // Adjust the timeout as needed
  };

  const isHomeOrMockInterviewer = location.pathname === '/' || location.pathname === '/mockInterviewerInput' || location.pathname === '/mockInterviewer';
  
  const mobileLoggedInNav = (
    <>
      <div className="mobile-redirect">
        {/* <a href="/resumaker" className="mobile-nav-item">
          <li>Manage Resume</li>
        </a> */}
        <a href="/mockInterviewerInput" className="mobile-nav-item">
          <li>Mock Interviewer</li>
        </a>
        {/* <a href="/search" className="mobile-nav-item">
          <li>Search Internships</li>
        </a> */}
        {/* <div className="students-nav mobile-nav-section">
          <a>
            <li className="li-dropdown-nav" onClick={change_students_dropdown}>
              User{' '}
              <img
                src={Arrow}
                className={`nav-arrow ${open_students_dropdown ? 'open' : ''}`}
              ></img>
            </li>
            <ul
              className={`dropdown-nav ${
                open_students_dropdown ? 'open-active' : 'close-inactive'
              }`}
            >
              <a href="/profilePage">
                <li>Profile</li>
              </a>
            </ul>
          </a>
        </div> */}
      </div>
      <a href="https://www.internguys.com/" className="mobile-nav-item">
        <li>Home</li>
      </a>
      {/* <a href="https://www.internguys.com/contact" className="mobile-nav-item">
        <li>Contact</li>
      </a> */}
      <button
        to=""
        name="Logout"
        onClick={loginState?.logoutUser}
        className="mobile-nav-login nav-sign-up"
      >
        Logout
      </button>
    </>
  );

  return (
    <>
      <div 
      className={`close-icon-box ${getToken() ? 'logged-in' : ''}`}
      style={getToken() ? {backgroundColor: '#213546'} : {}}
     > 
        {getToken() ? (
          <img
            src={Images.IG_LOGO} /* Change this to the image for logged-in state */
            alt="Intern guys"
            onClick={() => navigate("/")}
            className="navbar__logoOpenimg logged-in"
            
          />
        ) : (
          <img
            src={Images.IG_LOGO2} /* Change this to the image for logged-out state */
            alt="Intern guys"
            onClick={() => navigate("/")}
            className="navbar__logoOpenimg"
          />
        )}
        <button className="close-icon" onClick={() => setOpen(!open)}>
          <CloseIcon />
        </button>
      </div>
      {mobileState && getToken() ? (
        <div className="mobile-redirect logged-in">
          {mobileLoggedInNav}
        </div>
      ) : mobileState ? (
        <div className="mobile-redirect">
          <Link to={'/login'} className="mobile-nav-item">
            <li>Sign Up</li>
          </Link>
          {/* <a href="#" className="mobile-nav-item" onClick={scrollToComponentCheetah}>
            <li>Cheetah Apply</li>
          </a> */}
          {/* <a href="/mockInterviewer" className="mobile-nav-item">
            <li>Mock Interviewer</li>
          </a> */}
          {/* <a href="https://www.internguys.com/about" className="mobile-nav-item">
            <li>About Us</li>
          </a> */}
          <a href="https://internguys.promotekit.com" className="mobile-nav-item">
            <li>Affiliate</li>
          </a>
          {/* <a href="https://www.internguys.com/contact" className="mobile-nav-item">
            <li>Contact</li>
          </a> */}
          <a href="https://recruiter.internguys.com" className="mobile-nav-item">
            <li>Recruiters</li>
          </a>
        </div>
      ) : (
        <>
          <NavItem link="/" name="Home" />
          {getToken() ? (
            <>
              {/* <NavItem link="/resumaker" name="Build Resume" /> */}
              <NavItem link="/mockInterviewerInput" name="Mock Interviewer" />
              {/* <NavItem link="/search" name="Search Internships" /> */}
              {/* <NavItem link="/contact" name="Contact" /> */}
            </>
          ) : (
            <>
              <NavItem link="/about" name="About Us" />
              <NavItem link="/blog" name="Blog" />
              {/* <NavItem link="/contact" name="Contact" /> */}
              {/* <NavItem link="/mockInterviewer" name="Mock Interviewer" /> */}
              <NavItem link="https://internguys.promotekit.com" name="Affiliate" />
            </>
          )}
        </>
      )}

      {!getToken() ? (
        <>
          {!mobileState && (
            <div className={`${mobileState ? 'mobile-button-nav-container' : ''}`}>
              <button className={`nav-sign-up ${isHomeOrMockInterviewer ? 'home' : ''}`}>
                <Link to={'/login'} className="sign-up-link">
                  Sign Up
                </Link>
              </button>
            </div>
          )}
        </>
      ) : !mobileState ? (
        <>
          <div className="dropdown">
            <div className="students-nav"></div>
            <div className={getDropbtnClass()} name="User">
              <Link to="" name="Logout" onClick={loginState?.logoutUser}>
                Logout
              </Link>
            </div>
            {/* <div className="dropdown-contents">
              <NavItem
                link="/profilePage"
                name="Profile"
                style={{ color: 'black' }}
              />
              <Link to="" name="Logout" onClick={loginState?.logoutUser}>
                Logout
              </Link>
            </div> */}
          </div>
        </>
      ) : null}
    </>
  );
};

export default Navlist;
