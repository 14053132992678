import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import './style.css';
import { ReactMic } from 'react-mic';
import PersonZoom2 from "../../images/person-zoom2.png";
import VideoTimer from "../../images/video-timer.png";
import { Oval } from 'react-loader-spinner'; 
import { AssemblyAI } from 'assemblyai';
import ErrorIcon from "../../images/error-icon.png";
import CheckCircle from "../../images/CheckCircle.png";
import * as UpChunk from '@mux/upchunk';

function CandidateInterviewScreen() {
  const { candidateToInterviewId } = useParams(); // Extract candidateToInterviewId from the URL
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [history, setHistory] = useState([]);
  const [record, setRecord] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [time, setTime] = useState(0);
  const videoRef = useRef(null);
  const messageEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoraSpeaking, setIsNoraSpeaking] = useState(false);
  const [isInterviewDone, setInterviewDone] = useState(false);
  const [aiResponse, setAiResponse] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const audioContextRef = useRef(null);
  const audioDestinationRef = useRef(null);
  const [uploadId, setUploadId] = useState(null);

  const client = new AssemblyAI({
    apiKey: "d1ed33af12ac4d478785ac40bb4599a7"
  });


  useEffect(() => {
    const fetchCandidateInfo = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_GET_INTERVIEW_STARTED_DATA, {
          params: { candidateToInterviewId },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setCandidateInfo(response.data);
      } catch (error) {
        console.error("Failed to fetch candidate information", error);
      } finally {
        setLoading(false); // Stop loading when data is fetched
      }
    };

    fetchCandidateInfo();
  }, [candidateToInterviewId]);


  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.href);
      alert("You cannot go back during the interview.");
    };
  
    window.history.pushState(null, null, window.location.href); // Add initial state
    window.addEventListener("popstate", handlePopState);
  
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave? Your interview progress will be lost.";
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Change body style for mockInterviewerZoom
    document.body.style.backgroundColor = "#CDE1D6";
    
    // Cleanup function to reset styles when component is unmounted or when navigating away
    return () => {
      document.body.style.backgroundColor = "#203546";
    };
  }, []);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history]);

  useEffect(() => {
    // console.log("aiResponse: ", aiResponse);
  }, [aiResponse]);

  useEffect(() => {
    // Check if the browser supports the required codec
    const checkCodecSupport = () => {
        if (!MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus')) {
            console.error('Browser does not support required video codec');
        }
    };
    
    checkCodecSupport();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => prevTime + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${m}m ${s < 10 ? '0' : ''}${s}sec`;
  };

  const startRecording = () => {
    setRecord(true);
  };

  const stopRecording = () => {
    setRecord(false);
  };

  //This will retry the API twice if it fails for some reason in the first time
  const retryRequest = async (fn, retries = 2, interval = 1000) => {
    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        setHasError(false);
        return await fn();
      } catch (error) {
        if (attempt < retries - 1) {
          await new Promise(resolve => setTimeout(resolve, interval));
        } else {
          setHasError(true);
          throw error;
        }
      }
    }
  };

  const onStop = (recordedBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(recordedBlob.blob);
    setHasError(false);
    assemblySpeechToText(recordedBlob.blob); 
  };

  const assemblySpeechToText = async (audioBlob) => {
    setIsLoading(true);
    const transcript = await retryRequest(() =>
      client.transcripts.transcribe({ audio: audioBlob })
    );

    if (transcript.status === 'error') {
      console.error("Error transcribing audio", transcript.error);
    }

    handleSendMessage(transcript.text);
  }

  const toggleVideo = async () => {
    if (isVideoOn) {
      // Stop all active tracks if video is turned off
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        stream.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
  
      // Clean up the audio context
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    } else {
      try {
        // Create an audio context for mixing
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        audioDestinationRef.current = audioContextRef.current.createMediaStreamDestination();
  
        // Get user media (audio and video)
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
  
        // Set video stream for display
        if (videoRef.current) {
          videoRef.current.srcObject = stream; // Display video
        }
  
        // Extract user's audio track
        const userAudioTrack = stream.getAudioTracks()[0];
        const userAudioSource = audioContextRef.current.createMediaStreamSource(new MediaStream([userAudioTrack]));
  
        // Connect the user's audio to the mixed audio destination
        userAudioSource.connect(audioDestinationRef.current);
  
        // Create a MediaRecorder for the audio-only stream
        const combinedAudioStream = audioDestinationRef.current.stream;

        const mimeType = MediaRecorder.isTypeSupported("audio/webm;codecs=opus")
        ? "audio/webm;codecs=opus"
        : MediaRecorder.isTypeSupported("audio/mp4;codecs=mp4a.40.2")
        ? "audio/mp4;codecs=mp4a.40.2"
        : ""; // Fallback for unsupported browsers

        if (!mimeType) {
          throw new Error("Your browser does not support any compatible MIME types for recording.");
        }

        const mediaRecorder = new MediaRecorder(combinedAudioStream, {
          mimeType: mimeType,
          audioBitsPerSecond: 128000, // Adjust bitrate for audio quality
        });
  
        mediaRecorder.ondataavailable = (e) => {
          if (e.data && e.data.size > 0) {
            setRecordedChunks((prev) => [...prev, e.data]);
          }
        };
  
        mediaRecorder.start(1000); // Record in 1-second chunks
  
        // Handle cleanup after stopping
        mediaRecorder.onstop = () => {
          setRecordedChunks([]); // Clear chunks to reset the state
        };
      } catch (err) {
        console.error("Error accessing media devices:", err);
      }
    }
  
    setIsVideoOn(!isVideoOn);
  };

  const handleSendMessage = async (transcription) => {
    setHasError(false);
    const formData = new FormData();

    formData.append('position', candidateInfo?.companyInterview?.position);
    formData.append('jobDescription', candidateInfo?.companyInterview?.jobDescription);
    formData.append('UserMessage', transcription);
    formData.append('CompanyName', candidateInfo?.company?.companyName);

    setHistory(prevHistory => {
      const newHistory = [...prevHistory, { role: 'User', message: transcription }];    
      formData.append('History', JSON.stringify(newHistory));
      return newHistory;
    });

    try {
      const response = await retryRequest(() => axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/nora-candidate-interview-main`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }));
      const { response: aiResponse } = response.data;

      setAiResponse(aiResponse);

      const audioResponse = await retryRequest(() => axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/textToSpeech`, {
        text: aiResponse
      }, {
        responseType: 'blob'
      }));

      if (aiResponse.toLowerCase().includes("end of the interview")) {
        setInterviewDone(true);
      }

      if (audioResponse.status === 200) {
        const audioBlob = new Blob([audioResponse.data], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);

        const audio = new Audio(audioUrl);
        audio.volume = 1.0; 
        setIsNoraSpeaking(true);

        // Add Nora's audio to the recording if we have an audio context
        if (audioContextRef.current && audioDestinationRef.current) {
          const source = audioContextRef.current.createMediaElementSource(audio);
          source.connect(audioDestinationRef.current); // Connect to mixed audio
          source.connect(audioContextRef.current.destination); // Connect to playback
        }

        audio.play();
        setIsNoraSpeaking(true);
        audio.onended = () => {
          setIsNoraSpeaking(false);
        };
      } else {
        setHasError(true);
        console.error('Failed to convert text to speech');
      }

      setHistory(prevHistory => [
        ...prevHistory,
        { role: 'Assistant', message: aiResponse },
      ]);
    } catch (error) {
      setHasError(true);
      console.error('Error sending message:', error);
    }
    setIsLoading(false);
  };  

  const getMuxUploadUrl = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/createMuxUploadUrl`);
        const { uploadUrl, id } = response.data; // Extract URL and ID
        setUploadId(id); // Save uploadId in state
        return { uploadUrl, uploadId: id };
    } catch (error) {
        console.error("Failed to get Mux upload URL", error);
        throw new Error("Could not retrieve Mux upload URL");
    }
  };

  const handleEndInterview = async () => {
    if (isVideoOn) toggleVideo(); // Stop video recording

    // Proceed with feedback logic
    setFeedbackLoading(true);
    const formData = new FormData();
    setHistory((prevHistory) => {
        const newHistory = [...prevHistory, { role: "User", message: "last" }];
        formData.append("History", JSON.stringify(newHistory));
        return newHistory;
    });

    const generateFeedbackLoop = async () => {
      try {

          const response = await retryRequest(() => 
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/generateCandidateFeedback`, {
              Position: candidateInfo?.companyInterview?.position,
              JobDescription: candidateInfo?.companyInterview?.jobDescription,
              History: JSON.stringify(history),
              CompanyName: candidateInfo?.company?.companyName,
              CandidateToInterviewId: candidateToInterviewId,
            }, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'text/plain',
              },
            })
          );

          // Stop recording and retrieve video file
          if (recordedChunks.length > 0) {
            try {
                // Combine all recorded chunks into a single Blob
                const audioBlob = new Blob(recordedChunks, { type: 'audio/webm' });
        
                // Convert Blob to File
                const audioFile = new File([audioBlob], "interview-audio.webm", { type: "audio/webm" });
        
                // Get Mux upload URL
                const { uploadUrl, uploadId: retrievedUploadId } = await getMuxUploadUrl();
        
                // Upload the audio file
                const upload = UpChunk.createUpload({
                    endpoint: uploadUrl, // Authenticated Mux upload URL
                    file: audioFile, // The File object
                    chunkSize: 5120, // ~5MB chunks
                });
        
                // Monitor upload progress
                upload.on('progress', (progressEvent) => {
                    console.log(`Upload progress: ${progressEvent.detail}%`);
                });
        
                // Handle errors
                upload.on('error', (errorEvent) => {
                    console.error(`Error during upload: ${errorEvent.detail}`);
                });
        
                // Handle successful upload
                upload.on('success', async () => {
                  console.log("Audio upload completed successfully!");
              
                  try {
                      // Call the backend to get and save the playback ID
                      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/updatePlaybackId`, {
                          candidateToInterviewId, // From useParams
                          uploadId: retrievedUploadId, // This is the uploadId from Mux's createMuxUploadUrl response
                      });
              
                      if (response.data.success) {
                          console.log("Playback ID successfully saved");
                      } else {
                          console.error("Failed to update Playback ID:", response.data.message);
                      }
                  } catch (error) {
                      console.error("Error updating Playback ID:", error);
                  }
              
                  setRecordedChunks([]); // Clear chunks after successful upload
                  setFeedbackLoading(false);
                  setFinished(true);
                  setStarted(false);
                });

            } catch (error) {
                console.error("Error during audio upload:", error);
                setFeedbackLoading(false);
                setFinished(true);
                setStarted(false);
            }
          }
          return;
      } catch (error) {
        console.error('Error sending message:', error);
        setFeedbackLoading(false);
      }
    };
  
    generateFeedbackLoop();
  }; 

  const startInterview = () => {
    setStarted(true);
    setIsLoading(true);
     // Automatically turn on the video when starting the interview if it is off
    if (!isVideoOn) {
        toggleVideo();  // This will turn on the video and set isVideoOn to true
    }
    handleSendMessage("first");
  };

  return (
    <><div>
        {started && (
        <div className="interview-title">
            <div>{candidateInfo?.companyInterview?.position}</div>
            <div className="timer">
            <img src={VideoTimer} className="video-timer-styling" alt="Timer icon" />
            {formatTime(time)}
            </div>
        </div>
        )}
      </div>
      <div className="nora-form-2">
            {finished ? (
                <div className="completionScreen">
                    <div className="completion-content">
                        <img src={CheckCircle} alt="Completion Check" className="completion-icon" />
                        <h2>Your Interview with Nora is complete!</h2>
                        <p>You may now exit out of this browser.</p>
                    </div>
                </div>
            ) : (!started && !finished) ? (
                <div className="instructionsImage2">
                    <div className="instruction-container2">
                        <h1>Welcome to your {candidateInfo?.companyInterview?.position} Interview with Nora</h1>
                        <p>This is the <strong>actual interview</strong>, which consists of approximately <strong>10 questions</strong> and will last around <strong>20 minutes</strong>. Please <strong>do not exit</strong> this browser, as once you exit you will not be able to restart.</p>
                    </div>
                    <div className="instruction-checklist2">
                        <p><span className="checkmark">✔</span>  <strong> Please take this interview on Google Chrome for the best experience </strong></p>
                        <p><span className="checkmark">✔</span> Ensure you are in a quiet, noise-free environment.</p>
                        <p><span className="checkmark">✔</span> Click 'Start' to begin answering a question and 'Stop' to finish answering.</p>
                        <p><span className="checkmark">✔</span> Ensure your audio permissions are enabled once you start answering.</p>
                        <p><span className="checkmark">✔</span> Ensure your video permissions are enabled once you start answering.</p>
                    </div>
                    <button onClick={startInterview} className="start-button2">Start</button>
                </div>
            )
                : (
                    <>
                        {feedbackLoading && (
                            <div className="loading-overlay">
                                <Oval color="#00BFFF" height={80} width={80} />
                                <div className="loading-text">Processing your interview (~2 minutes)! Please don't close this page </div>
                            </div>
                        )}
                        <div className="controls-section-2">
                            <video ref={videoRef} autoPlay muted className={`video2-feed ${isVideoOn ? '' : 'hidden'}`}></video>

                            <div className="controls2">
                                {(!isLoading && !isNoraSpeaking && !isInterviewDone) && (
                                    <div>
                                        <ReactMic
                                            record={record}
                                            className="mic-styling2"
                                            onStop={onStop}
                                            visualSetting="frequencyBars" />
                                    </div>
                                )}
                                <div className="action-buttons">
                                    <button disabled={(!record && (isLoading || isNoraSpeaking || isInterviewDone))} onClick={record ? stopRecording : startRecording}
                                        type="button"
                                        className={`recordBtn round-btn ${record ? 'stop-recording' : ''} ${(!record && (isLoading || isNoraSpeaking || isInterviewDone)) ? 'disabled' : ''}`}>
                                        {record ? 'Stop Answering' : 'Start Answering'}
                                    </button>
                                    <div className="video-container-2">
                                        <div className="video-wrapper-2">
                                            {<img className="person-zoom" src={PersonZoom2} alt="Video of user" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="transcript-container">
                            <h2 style={{ textAlign: 'center' }}>Live Transcript</h2>
                            <div className="message-history">
                                {history.map((msg, index) => (
                                    msg.message !== "first" && msg.message !== "last" && (
                                        <div key={index} className={`message ${msg.role}`}>
                                            {msg.message}
                                        </div>
                                    )
                                ))}
                                {hasError && (
                                    <div className="error-message">
                                        <img src={ErrorIcon} alt="Error Icon" className="error-icon" />
                                        <span>Oops, we couldn't capture your speech. Please try recording again.</span>
                                    </div>
                                )}
                                {isLoading && (
                                    <div className="">
                                        <Oval color="#00BFFF" height={20} width={20} />
                                        <span>Nora is thinking...</span>
                                    </div>
                                )}
                                <div ref={messageEndRef} />
                            </div>
                            <button onClick={handleEndInterview} type="button" className="endInterviewBtn2">End Interview</button>
                        </div>
                    </>
                )}
          </div>
        </>
  );
}

export default CandidateInterviewScreen;