import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import Logo from "../../images/ig-logo-black.png";
import CheckCircle from "../../images/CheckCircle.png";

function CandidateApplicationScreen() {
  const { companyId, companyInterviewId } = useParams();
  const [positionDetails, setPositionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [finished, setFinished] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    location: "",
    currentRole: "",
    linkedIn: "",
    resume: null,
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const getPositionDetailsEndpoint = `${process.env.REACT_APP_GET_POSITION_DETAILS}`;
  const getInterviewLinkEndpoint = `${process.env.REACT_APP_GET_INTERVIEW_LINK}`;

  useEffect(() => {
    const fetchPositionDetails = async () => {
      try {
        const response = await fetch(
          `${getPositionDetailsEndpoint}?companyInterviewId=${companyInterviewId}&companyId=${companyId}`
        );

        if (!response.ok) {
          const errorData = await response.json();
          setError(errorData.Message || "Failed to fetch position details.");
          setLoading(false);
          return;
        }

        const data = await response.json();
        setPositionDetails(data);
        setLoading(false);
      } catch (error) {
        setError("An error occurred while fetching position details.");
        setLoading(false);
      }
    };

    fetchPositionDetails();
  }, [companyId, companyInterviewId]);

  useEffect(() => {
    // Check if required fields are filled to validate the form
    const { name, email, location, resume } = formData;
    const formValid =
      name.trim() !== "" &&
      email.trim() !== "" &&
      location.trim() !== "" &&
      resume !== null;
    setIsFormValid(formValid);
  }, [formData]); 

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      resume: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare form data for API
    const payload = new FormData();
    payload.append("companyInterviewId", companyInterviewId);
    payload.append("Name", formData.name);
    payload.append("Email", formData.email);
    payload.append("Location", formData.location);
    payload.append("CurrentRole", formData.currentRole || ""); // Optional field
    payload.append("LinkedIn", formData.linkedIn || ""); // Optional field
    if (formData.resume) {
      payload.append("file", formData.resume);
    }

    try {
      const response = await fetch(getInterviewLinkEndpoint, {
        method: "POST",
        body: payload,
      });

      if (!response.ok) {
        const errorData = await response.json();
        alert(errorData.Message || "Failed to submit the application.");
        return;
      }

      const data = await response.json();
      alert(`Application submitted successfully!`);
      setFinished(true);
      // setShowForm(false); 
    } catch (error) {
      alert("An error occurred while submitting your application.");
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="candidate-application-container">
      <div className="candidate-application-header">
        <img src={Logo} alt="Intern Guys Logo" className="candidate-application-logo" />
      </div>

      {positionDetails?.active === false ? (
        <div className="completionScreen" style={{ marginTop: "5%" }}>
          <h2>This position is closed!</h2>
        </div>
      ) : (
        finished ? (
          <div className="completionScreen" style={{ marginTop: "5%" }}>
            <div className="completion-content">
              <img
                src={CheckCircle}
                alt="Completion Check"
                className="completion-icon"
              />
              <h2>We have received your application!</h2>
              <p>We will get back to you ASAP. You may now close this browser window.</p>
            </div>
          </div>
        ) : (
          <div className="candidate-application-content">
            <h1 className="position-title">
              {positionDetails?.companyName}, {positionDetails?.position}
            </h1>
            <p className="position-location">
              {positionDetails?.positionLocation} | {positionDetails?.positionType}
            </p>

            {!showForm ? (
              <>
                <h2 className="section-title3">About the Position</h2>
                <div
                  className="job-description"
                  dangerouslySetInnerHTML={{
                    __html: positionDetails?.jobDescription,
                  }}
                />
                <button className="apply-button3" onClick={() => setShowForm(true)}>
                  Apply
                </button>
              </>
            ) : (
              <form className="application-form" onSubmit={handleSubmit}>
                <div className="form-row">
                  <label>
                    Full Name *
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Full Name"
                      required
                      value={formData.name}
                      onChange={handleFormChange}
                    />
                  </label>
                  <label>
                    Email *
                    <input
                      type="email"
                      name="email"
                      placeholder="youremail@email.com"
                      required
                      value={formData.email}
                      onChange={handleFormChange}
                    />
                  </label>
                </div>
                <div className="form-row">
                  <label>
                    Location *
                    <input
                      type="text"
                      name="location"
                      placeholder="e.g. San Diego, CA"
                      required
                      value={formData.location}
                      onChange={handleFormChange}
                    />
                  </label>
                  <label>
                    Current Role
                    <input
                      type="text"
                      name="currentRole"
                      placeholder="e.g. Software Engineer I"
                      value={formData.currentRole}
                      onChange={handleFormChange}
                    />
                  </label>
                </div>
                <div className="form-row">
                  <label>
                    LinkedIn
                    <input
                      type="url"
                      name="linkedIn"
                      placeholder="Your LinkedIn URL"
                      value={formData.linkedIn}
                      onChange={handleFormChange}
                    />
                  </label>

                  <label>
                    Upload Resume *
                    <input
                      type="file"
                      required
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
                <div className="form-buttons2">
                  <button
                    type="button"
                    className="back-button2"
                    onClick={() => setShowForm(false)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="submit-button2"
                    disabled={!isFormValid} 
                    style={{
                      backgroundColor: isFormValid ? "#1daf5c" : "#cccccc",
                      cursor: isFormValid ? "pointer" : "not-allowed",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default CandidateApplicationScreen;