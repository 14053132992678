import "./App.css";
import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Homepage from "./views/homepage";
import About from "./views/aboutUs/AboutUs.js";
import Contact from "./views/contact";
import Login from "./views/login";
import Search from "./views/search";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import Navbar from "./components/Navbar/Navbar";
import PortalNav from "./components/portalNav";
import { logout, getToken } from "./authetication/loginState";
import Resumaker from "./views/Resumaker/Resumaker";
import ViewTemplates from "./views/ViewTemplates/viewTemplates";
import ResumeAsking from "./views/ResumeAsking";
import UploadResume from "./views/UploadResume";
import ProfilePage from "./views/profile/index";
import PreferenceMain from "./views/intershipPreferenceQuestions/preferenceMain";
import TempCompany from "./views/TempCompany/tempCompany";
import InternshipPreferenceQuestions from "./views/intershipPreferenceQuestions/internshipPreferenceQuestions";
import ResetPasswordForm from "./components/ResetPasswordForm/ResetPasswordForm";
import NoraAnnouncement from "./components/NoraAnnouncement/NoraAnnouncement";
import PressPage from "./views/Press/PressPage";
import Blog from "./views/blog/Blog";
import BlogDetails from './views/blogDetails/BlogDetails';
import BlogWriter from "./views/blogwriter/BlogWriter";
import MockInterviewerInput from "./views/MockInterviewerInput";
import MockInterviewerZoom from "./views/MockInterviewerZoom";
import MockInterviewerFeedback from "./views/MockInterviewerFeedback";
import MockInterviewer from "./views/MockInterviewer";
import CandidateInterviewScreen from "./views/CandidateInterviewScreen";
import CandidateApplicationScreen from "./views/CandidateApplicationScreen";

import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKER; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Intern Guys GA" });

function App() {
  let navigate = useNavigate();
  let location = useLocation();
  const [loggedIn, setLoggedIn] = useState(getToken() ? true : false);
  const logoutUser = () => {
    logout();
    setLoggedIn(false);
    navigate("/");
  };
  useEffect(() => { }, [loggedIn]);
  const [blogs, setBlogs] = useState([]);
  const [featuredBlog, setFeaturedBlog] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_GET_BLOGS);
      if (response.ok) {
        let data = await response.json();
        setBlogs(data);
        setFeaturedBlog(data.filter((blog) => blog.featured === true));
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={location.pathname === "/" && !loggedIn ? "newNavbarApp": 'App'}>
      {/* { !getToken() && location.pathname !== "/login" && location.pathname !== "/resetpassword" ? <NoraAnnouncement /> : null} */}
      {!getToken() && location.pathname !== "/login" && location.pathname !== "/resetpassword" ? <PortalNav /> : null}
      {location.pathname !== "/login" && location.pathname !== "/resetpassword" && <Navbar loginState={{ loggedIn, logoutUser, navigate }} />}
      <Routes>
        <Route path="/login" element={<Login loginState={{ loggedIn, setLoggedIn, navigate }} />} />
        <Route path="/resetpassword" element={<ResetPasswordForm loginState={{ navigate }} />} />
        <Route path="/" element={<Homepage loginState={{ loggedIn, navigate }} />} />
        <Route path="/about" element={<About />} />
        <Route path="/press" element={<PressPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/linkedin-login" element={<LinkedInPopUp />} />
        <Route path="/resumaker" element={<Resumaker loginState={{ loggedIn, navigate }} />} />
        <Route path="/viewTemplates/:resumeId" element={<ViewTemplates />} />
        <Route path="/ResumeAsking" element={<ResumeAsking />} />
        <Route path="/UploadResume" element={<UploadResume />} />
        <Route path="/profilePage" element={<ProfilePage />} />
        <Route path="/internshipPreferenceQuestions" element={<PreferenceMain />} />
        <Route path="/search" element={<Search />} />
        <Route path="/temp" element={<TempCompany />} />
        <Route path="/signupQuestions" element={<InternshipPreferenceQuestions />} />
        <Route path="/blog" element={<Blog blogs={blogs} featuredBlog={featuredBlog} />} />
        <Route path="/blog-details/:blogId" element={<BlogDetails />} />
        <Route path="/blog-writer" element={<BlogWriter />} />
        <Route path="/mockInterviewer" element={<MockInterviewer />} />
        <Route path="/mockInterviewerInput" element={<MockInterviewerInput />} />
        <Route path="/mockInterviewerZoom" element={<MockInterviewerZoom />} />
        <Route path="/mockInterviewerFeedback" element={<MockInterviewerFeedback />} />
        <Route path="/interview/:candidateToInterviewId" element={<CandidateInterviewScreen />} />
        <Route path="/job/:companyInterviewId/company/:companyId" element={<CandidateApplicationScreen />} />
      </Routes>
    </div>
  );
}

export default App;
